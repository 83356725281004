<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="申请时间">
                <el-date-picker v-model="time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期"
                    end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="申请单号">
                <el-input size="small" v-model="form.withdraw_no"></el-input>
            </el-form-item>
            <el-form-item label="审核状态">
                <el-select size="small" v-model="form.status" placeholder="请选择">
                    <el-option v-for="item in status" :key="item.val" :label="item.label" :value="item.val"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label=" " label-width="25px">
                <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
                <!--<el-button type="primary" @click="searchType" size="small">导出</el-button>-->
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="name" label="申请时间" align="center">
                <template slot-scope="scope">{{ getDateformat(scope.row.create_time) }}</template>
            </el-table-column>
            <el-table-column prop="withdraw_no" label="申请单号" align="center"> </el-table-column>
            <el-table-column label="申请提现金额" align="center">
                <template slot-scope="scope">￥{{ scope.row.withdraw_amount }}</template>
            </el-table-column>
            <el-table-column label="打款金额" align="center">
                <template slot-scope="scope">￥{{ scope.row.remit_amount }}</template>
            </el-table-column>
            <el-table-column prop="order_count" label="结算订单数" align="center"></el-table-column>
            <el-table-column label="账号信息" width="260">
                <template slot-scope="scope">
                    <div>开户行：{{ scope.row.bank_info }}</div>
                    <div>银行卡号：{{ scope.row.bank_card }}</div>
                    <div>持卡人：{{ scope.row.bank_name }}</div>
                </template>
            </el-table-column>

            <el-table-column label="审核状态" align="center">
                <template slot-scope="scope">
                    <div :style="{ 'color': status.find(v => v.val == scope.row.status).color }">{{ status.find(v => v.val
                        ==
                        scope.row.status).label }}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button @click="$router.push({ path: '/finance/withdrawDetail', query: { id: scope.row.id } })"
                        type="text" size="small">查看详情</el-button>
                    <el-button @click="handleVoucher(scope.row.voucher)" type="text" size="small"
                        v-if="scope.row.status == 2">打款凭证</el-button>
                    <el-popover placement="top-start" width="200" trigger="click" :content="scope.row.remark">
                        <el-button type="text" size="small" slot="reference" v-if="scope.row.status == -1"
                            style="margin-left:10px">驳回原因</el-button>
                    </el-popover>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
        <el-dialog :visible.sync="dialogVisible_image" width="30%" title="打款凭证">
            <el-image style="width: 200px; height: 200px;margin: 0 auto;display: block;" :src="voucher"
                :preview-src-list="[voucher]">
            </el-image>
        </el-dialog>
    </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging
    },
    data () {
        return {
            form: {
                page: 1,
                rows: 10,
                start_time: '',
                end_time: '',
                withdraw_no: '',
                status: ''
            },
            time: '',
            status: [
                {
                    val: -1,
                    label: '已驳回',
                    color: '#F56C6C'
                },
                {
                    val: 1,
                    label: '待审核',
                    color: '#E6A23C'
                },
                {
                    val: 2,
                    label: '已结算',
                    color: '#409EFF'
                }
            ],
            list: [],
            total: 0,
            voucher: '',
            dialogVisible_image: false
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat,
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getList();
            } else {
                this.form.page = val;
                this.getList();
            }
        },
        getList (page = 0) {
            if (page) {
                this.form.page = 1
            }
            if (this.time) {
                this.form.start_time = this.time[0] / 1000
                this.form.end_time = this.time[1] / 1000
            } else {
                this.form.start_time = ''
                this.form.end_time = ''
            }
            this.$axios
                .post(this.$api.finance.withdrawList, this.form)
                .then(res => {
                    if (res.code == 0) {
                        let { total_number, list } = res.result
                        this.total = total_number
                        this.list = list
                    }
                });
        },
        cancelSearch () {
            this.form = {
                page: 1,
                rows: 10,
                u_mobile: '',
                artisan_name: '',
                mobile: '',
                start_time: '',
                end_time: '',
                withdraw_no: '',
                status: ''
            }
            this.getList()
        },
        handleVoucher (voucher) {
            this.voucher = voucher
            this.dialogVisible_image = true
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}

.descriptions {
    font-size: 18px;
    font-weight: bold;
    padding: 30px 0;
    display: flex;

    .descriptions_item+.descriptions_item {
        margin-left: 50px;
    }
}
</style>
